import React, { Fragment } from 'react';
import { getEditorPreloader } from '@confluence-classic/confluence-fabric-editor/src/editorPreloader';

import { EditContentButtonPreloaderContext } from '@confluence/edit-button';
import { EmbeddedViewPageLoader } from '@confluence/embedded-confluence';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { ViewPageLoader } from '@confluence/view-page';
import { PageShownPublicLinksEventLoader } from '@confluence/external-share-utils/entry-points/PageShownPublicLinksEventLoader';
import { PresenterModeContextProvider } from '@confluence/presentation-mode';

import SendTrackViewPageEvent from '../../SendTrackViewPageEvent';
import { ContentExtensionsProvider } from '../../ContentExtensionsProvider';
import { CommentsSectionLoader } from '../../CommentsSection';
import { ClassicEditorContextProviderLoader } from '../../ClassicEditorContextProvider';
import SpaceBase from '../../SpaceBase';
import { LazyDwellTime, LazyMobileWebContainer } from '../lazyComponents';
import { CommentContextWrapper } from '../commentContextWrapper';

/**
 * This component handles page, blog and space overview routes
 */
export const ViewPageRouteComponent = ({
	spaceKey,
	contentId,
	isPage,
	isMobile,
	isEmbeddedConfluence,
}) => {
	const pageType = isPage ? 'page' : 'blogpost';

	let component;
	if (isEmbeddedConfluence) {
		component = (
			<CommentContextWrapper>
				<EmbeddedViewPageLoader
					contentId={contentId}
					spaceKey={spaceKey}
					classicComments={CommentsSectionLoader}
					ClassicEditorContextProviderLoader={ClassicEditorContextProviderLoader}
				/>
			</CommentContextWrapper>
		);
	} else if (isMobile) {
		component = (
			<CommentContextWrapper>
				<LazyMobileWebContainer />
			</CommentContextWrapper>
		);
	} else {
		component = (
			<CommentContextWrapper>
				<EditContentButtonPreloaderContext.Provider value={getEditorPreloader()}>
					<PresenterModeContextProvider contentId={contentId} spaceKey={spaceKey}>
						<ViewPageLoader
							spaceKey={spaceKey}
							contentId={contentId}
							classicComments={CommentsSectionLoader}
							ClassicEditorContextProviderLoader={ClassicEditorContextProviderLoader}
						>
							<ErrorBoundary attribution={Attribution.BACKBONE}>
								<SendTrackViewPageEvent pageType={pageType} />
							</ErrorBoundary>
						</ViewPageLoader>
					</PresenterModeContextProvider>
				</EditContentButtonPreloaderContext.Provider>
			</CommentContextWrapper>
		);
	}

	return (
		<Fragment>
			<SpaceBase>
				<ContentExtensionsProvider>{component}</ContentExtensionsProvider>
			</SpaceBase>
			<LazyDwellTime />
			<PageShownPublicLinksEventLoader contentId={contentId} isPage={isPage} />
		</Fragment>
	);
};
